<template>
  <AppLayout>
    <div class="xtx-goods-page">
      <div class="container">
        <Breadcrumb>
          <BreadcrumbItem to="/">首页</BreadcrumbItem>
          <BreadcrumbItem  to="/message" >消息中心</BreadcrumbItem>
          <BreadcrumbItem>消息详情</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <div class="order-title">
            <h1>{{ data.title }}</h1>
            <div class="subtitle">
              <span>发布日期：{{ data.createDate }}</span>
              <!-- <span>阅读量：{{ details.readAmount }}</span> -->
            </div>
          </div>
          <div class="main-content">
						  <p v-html=" data.content"></p>
           <!-- {{ data.content }} -->
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout'

export default {
  name: 'MessageDetail',
  components: {
    AppLayout,
  },
  data () {
    return {
      data: {},
    }
  },
	created () {
		 this.init()

	},
  methods: {
    init () {

      this.$store
        .dispatch('NoticeDetails', this.$route.query.messageId)
        .then((response) => {
					this.data = response
        })
        .catch(() => {})
    },
	},
  setup () {},
}
</script>

<style scoped lang="less">
.container {
  margin-top: 12px;
  .container-time {
    font-size: 12px;
    color: #999999;
  }
  .container-header {
    font-size: 18px;
    font-weight: 600;
  }
  .order-title {
    text-align: center;
    .subtitle {
      color: #acacac;
      font-size: 12px;
      text-align: center;
      span {
        padding: 10px 5px;
        display: inline-block;
      }
    }
  }
}

.order-title {
  text-align: center;

  .subtitle {
    color: #acacac;
    font-size: 12px;
    text-align: center;

    span {
      padding: 10px 5px;
      display: inline-block;
    }
  }
}

.main-content {
	margin-bottom: 20px;
	min-height: 500px;
  .label {
    color: #727477;
  }

  /deep/ .ivu-row {
    padding: 8px 0;
  }
}


</style>
